<template>
    <div class="selfRefundCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                :model="form"
                size="small"
                ref="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易机构" prop="deptCode">
                            <el-select
                                v-model="form.deptCode"
                                ref="deptSelect"
                                v-if="meta.depts.length"
                                @change="handleQueryPayType"
                                filterable
                            >
                                <el-option
                                    v-for="item in meta.depts"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-date-picker v-model="form.createDate" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 8px">
                    <el-col :span="8">
                        <el-form-item label="退款业务员" prop="refundCashierCode">
                            <el-select v-model="form.refundCashierCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    :value="g.code"
                                    :key="g.code"
                                    :label="g.realName"
                                    v-for="g in meta.managedStaffs"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款类型" prop="payTemplateCode" filterable>
                            <el-select v-model="form.payTemplateCode">
                                <el-option
                                    v-for="item in meta.payTypes"
                                    :label="item.template.name"
                                    :value="item.template.code"
                                    :key="item.template.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="实退金额" prop="realRefundMoney">
                            <ef-price-input
                                v-model.number="form.realRefundMoney"
                                :precision="2"
                                @change="calculateRefundMoney"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="会员手机号">
                            <el-input v-model="form.memberMobile" style="width: 135px" />
                            <el-button
                                type="primary"
                                @click="handleQueryMember"
                                size="small"
                                :disabled="!form.memberMobile || !form.deptCode"
                                >搜索会员
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员姓名">
                            <el-input readonly v-model="form.memberName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input v-model="form.search" placeholder="商品名称" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="备注">
                            <el-input
                                v-model="form.remarks"
                                type="textarea"
                                maxlength="200"
                                show-word-limit
                                :rows="3"
                                style="width: 400%"
                                placeholder="字数不超过200"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="queryAndAdd" size="small" :disabled="!form.deptCode || !form.search">
                查询并添加
            </el-button>
            <el-button type="primary" @click="handleSelectGoods" size="small" :disabled="!form.deptCode"
                >选择商品基本信息
            </el-button>
            <el-button type="primary" @click="handleSave" size="small" :disabled="tableData.length <= 0"
                >保存
            </el-button>
        </el-card>
        <pick-up-goods ref="pickUpGoods" @queryGoods="handleSelectGoodsOk" />
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="180" />
                <el-table-column prop="sku.bars" label="条码" width="180">
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.sku.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="130" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="100" />
                <el-table-column label="退货数量" width="120" prop="counts">
                    <template slot-scope="scope">
                        <ef-stock-biz-count-input
                            v-model="scope.row.counts"
                            :sku-type="scope.row.sku.type"
                            @change="calculateRefundMoney"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="零售价" width="120" prop="retailPrice">
                    <template slot-scope="scope">{{ scope.row.retailPrice | money }}</template>
                </el-table-column>
                <el-table-column label="退货金额" width="160" prop="refundMoney">
                    <template slot-scope="scope">
                        {{ scope.row.refundMoney }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteRow(scope.$index, tableData, scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import MoneyUtils from 'js/MoneyUtils';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import EfPriceInput from 'components/EfPriceInput';
import Decimal from 'decimal';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'SelfRefundCreate',
    components: { PickUpGoods, EfPriceInput, EfStockBizCountInput },
    data() {
        return {
            form: {
                refundCashierCode: '',

                memberName: '',
                memberMobile: '',
                memberCode: '',

                realRefundMoney: 0.0,
                deptCode: '',
                payTemplateCode: '',

                repoCode: '',
                discount: 10,
                code: '',

                createDate: new Date(),
                creator: this.$store.state.session.name,

                remarks: '',
            },
            tableData: [],
            rules: {
                deptCode: [{ required: true, message: '请选择交易部门', trigger: 'blur' }],
                realRefundMoney: [{ required: true, message: '请输入退款金额', trigger: 'blur' }],
                payTemplateCode: [{ required: true, message: '请选择支付方式', trigger: 'blur' }],
                refundCashierCode: [{ required: true, message: '请选择退货业务员', trigger: 'blur' }],
            },
            url: {
                queryGoods: '/goods/goods/pageExtend',
                save: '/selfRefund/cash/createExtend',
                queryPayType: '/finance/pay/payType/page',
                queryMember: '/member/page',
                queryMemberDiscount: '/member/level/queryMemberLevel',
            },
            queryData: {
                page: 1,
                search: '',
                deptCode: '',
                limit: Util.Limit,
            },
            meta: {
                depts: [],
                deptMap: new Map(),
                payTypes: [],
                managedStaffs: [],
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.meta.depts = rst;
            this.form.deptCode = rst[0].code;
            rst.forEach((v) => {
                this.meta.deptMap.set(v.code, v.deptGroupCode);
            });
            const _params = { params: { deptCode: rst[0].code } };
            Util.queryTable(this, this.url.queryPayType, _params, (data) => {
                this.meta.payTypes = data.data;
            });
        });
        this.$efApi.staffApi.allRelatedStaff().then((rst = []) => {
            const temStaffCodes = [];
            this.meta.managedStaffs = rst.filter(
                //排除掉超级管理员(code:TRSSTF00000000000001)，过滤出可以展示的退货业务员
                (e) => {
                    if (
                        e.code !== 'TRSSTF00000000000001' &&
                        this.canShowRefundCashier(e.code) &&
                        !temStaffCodes.includes(e.code)
                    ) {
                        temStaffCodes.push(e.code);
                        return true;
                    } else {
                        return false;
                    }
                }
            );
        });
    },
    methods: {
        queryAndAdd() {
            const _this = this;
            const disable =
                _this.form.search != '' &&
                _this.form.deptCode != '' &&
                _this.form.realRefundMoney != 0 &&
                _this.form.realRefundMoney != '';
            if (!disable) {
                _this.$message.error('请选择交易部门并输入查询条件和实退金额');
                return;
            }
            const _params = { params: { deptCode: this.form.deptCode, search: this.form.search } };
            Util.queryTable(_this, _this.url.queryGoods, _params, (data) => {
                if (data.status == 200) {
                    let _a = this.tableData.concat(data.data);
                    _a = this.duplicate(_a, 'skuCode');
                    this.tableData = _a;
                }
            });
        },
        handleSelectGoods() {
            if (this.form.realRefundMoney <= 0.0 || this.form.deptCode == '') {
                this.$message.error('请选择机构并填写实退金额');
                return;
            }
            const deptName = this.$refs.deptSelect.selected.label;
            this.$refs.pickUpGoods.show({ deptCode: this.form.deptCode, deptType: 3 }, deptName);
        },
        handleSelectGoodsOk(data) {
            let _a = this.tableData.concat(data);
            _a = this.duplicate(_a, 'skuCode');
            this.tableData = _a;
        },
        handleSave: function () {
            const _this = this;
            this.$refs.form.validate();
            if (this.form.realRefundMoney <= 0) {
                this.$message.error('实退金额不能为0');
                return false;
            }
            if (this.tableData.filter((r) => Util.isEmpty(r.retailPrice)).length !== 0) {
                this.$message.error('请重新选择有零售价的商品');
                return 0;
            }
            if (this.tableData.filter((r) => !r.counts).length) {
                this.$message.error('请正确填写退货数量');
                return 0;
            }
            if (this.tableData.length === 0) {
                this.$message.error('请选择至少一件商品');
                return;
            }
            const totalRefund = this.tableData.reduce(
                (total, item) =>
                    Decimal(total)
                        .add(Decimal(item.refundMoney || 0))
                        .toNumber()
                        .toFixed(2),
                0
            );
            if (Number(totalRefund) != Number(this.form.realRefundMoney)) {
                this.$message.error('实退总金额与商品的实退金额不一样');
                return;
            }
            this.$refs.form.validate((valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return;
                }
                const _params = {
                    memberCode: _this.form.memberCode,
                    deptCode: _this.form.deptCode,
                    realRefundMoney: MoneyUtils.moneyToDb(_this.form.realRefundMoney),
                    discount: _this.form.discount,
                    payTemplateCode: _this.form.payTemplateCode,
                    refundCashierCode: _this.form.refundCashierCode,
                    remarks: _this.form.remarks,

                    goodsCode: _this.tableData.map((v) => v.code || ' '),
                    refundCounts: _this.tableData.map((v) => v.counts),
                    retailPrices: _this.tableData.map((v) => v.retailPrice || 0),
                    discountPrices: _this.tableData.map((v) => v.retailPrice || 0),
                    refundMoney: _this.tableData.map((v, i) => MoneyUtils.moneyToDb(v.refundMoney || 0)),
                };
                UrlUtils.PostRemote(this, this.url.save, _params);
            });
        },

        deleteRow(index, rows, row) {
            rows.splice(index, 1);
            this.calculateRefundMoney();
        },
        calculateRefundMoney() {
            const arr = JSON.parse(JSON.stringify(this.tableData));
            if (arr.length <= 0) {
                return;
            }
            const canNotComputeIdx = arr.findIndex((row) => {
                return Util.isEmpty(row.retailPrice) || Util.isEmpty(row.counts);
            });
            if (Util.isEmpty(this.form.realRefundMoney) || canNotComputeIdx >= 0) {
                for (const row of arr) {
                    row.discountPrice = null;
                    row.discountMoney = null;
                    row.refundMoney = null;
                }
            } else {
                let sumDiscountMoney = 0;
                for (let i = 0; i < arr.length; i++) {
                    const row = arr[i];
                    //折扣  discount
                    const temDiscount = Decimal(this.form.discount).div(10).toNumber();
                    //零售价 retailPrice
                    const temYuanDiscountPrice = Decimal(MoneyUtils.moneyToYuan(row.retailPrice))
                        .mul(temDiscount)
                        .toNumber()
                        .toFixed(2);
                    row.discountPrice = temYuanDiscountPrice;
                    //折扣单件商品价格*数量     temYuanDiscountMoney
                    const temYuanDiscountMoney = Decimal(temYuanDiscountPrice).mul(row.counts).toNumber().toFixed(2);
                    row.discountMoney = temYuanDiscountMoney;
                    //       sumDiscountMoney 所有商品总价
                    sumDiscountMoney = Decimal(sumDiscountMoney).add(temYuanDiscountMoney).toNumber().toFixed(2);
                }
                let sumRefundMoneyWithoutLast = 0;
                for (let i = 0; i < arr.length; i++) {
                    const row = arr[i];
                    //    discountMoney未打折扣的单件总价  realRefundMoney应退金额
                    const temYuanRefundMoney = Decimal(row.discountMoney)
                        .mul(Decimal(this.form.realRefundMoney))
                        .div(sumDiscountMoney)
                        .toNumber()
                        .toFixed(2);
                    row.refundMoney = temYuanRefundMoney;
                    if (i == arr.length - 1) {
                        row.refundMoney = Decimal(this.form.realRefundMoney)
                            .sub(Decimal(sumRefundMoneyWithoutLast))
                            .toNumber()
                            .toFixed(2);
                    } else {
                        sumRefundMoneyWithoutLast = Decimal(sumRefundMoneyWithoutLast)
                            .add(Decimal(temYuanRefundMoney))
                            .toNumber()
                            .toFixed(2);
                    }
                }
            }
            this.tableData = arr;
        },
        handleQueryPayType() {
            const _this = this;
            _this.tableData = [];
            _this.form.memberMobile = '';
            _this.form.memberName = '';
            _this.form.payTemplateCode = '';
            _this.form.realRefundMoney = 0;
            const _params = { params: { deptCode: _this.form.deptCode } };
            Util.queryTable(_this, _this.url.queryPayType, _params, (data) => {
                _this.meta.payTypes = data.data;
            });
        },
        handleQueryMember() {
            const _this = this;
            _this.form.memberName = '';
            const reg = /^((13[0-9])|141|(14[5-9])|(15([0-3]|[5-9])|166|17([0-9])|18[0-9]|19[8-9]))\d{8}$/;
            if (!reg.test(_this.form.memberMobile)) {
                this.$message.error('手机号填写不正确');
                return;
            }
            // let _params =  {search: _this.form.memberMobile}
            (async () => {
                await Util.queryTable(
                    _this,
                    _this.url.queryMember + '?search=' + _this.form.memberMobile,
                    null,
                    (data) => {
                        if (data.count == 0) {
                            this.$message.error('会员不存在');
                            return;
                        }
                        if (data.data.length == 1) {
                            _this.form.memberName = data.data[0].name || '';
                            _this.form.memberCode = data.data[0].code || '';
                            const _levelParam = {
                                params: {
                                    deptGroup: _this.meta.deptMap.get(_this.form.deptCode),
                                    memberCode: _this.form.memberCode,
                                },
                            };
                            Util.queryTable(_this, _this.url.queryMemberDiscount, _levelParam, (rst) => {
                                _this.form.nowLevel = rst.data.nowLevel;
                                _this.from.discount = rst.data.nowDiscount || 10;
                            });
                        }
                    }
                );
                _this.calculateRefundMoney();
            })();
        },
        canShowRefundCashier(developMember = '') {
            const staffType = this.$store.state.session.staffType;
            const staffCode = this.$store.state.session.code;
            //当前登录用户是管理人员或者推荐人是登录用户则显示推荐人信息
            return staffType === 0 || staffCode === developMember;
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
